import React from "react"
import Title from "./title"
import Imgix from "react-imgix"
import styles from "../styles/scss/components/content.module.scss"
import cn from "classnames"
import VideoBlock from "./videoBlock"
import Gallery from "./gallery"
import ImageBlock from "./imageBlock"
import { supSub } from "../services/util"

const buildContents = props => {
    const { content, pageTitle = "" } = props
    if (content === null || content === undefined) {
        return ""
    }

    var out = []
    var curTextImageRow = 0
    const ctObjs = Object.values(content)
    for (var i = 0; i < ctObjs.length; i++) {
        const c = ctObjs[i]
        switch (c.__typename) {
            case "DatoCMS_TextRecord":
                out.push(
                    <section key={c.id} className={styles.textRow}>
                        {pageTitle !== c.subtitle && c.subtitle && (
                            <Title className={styles.title} value={supSub(c.subtitle)} />
                        )}
                        {c.body && (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: supSub(c.body),
                                }}
                            />
                        )}
                    </section>
                )
                break

            case "DatoCMS_TextAndImageRecord":
                const SocialAnchors = (props) => {
                    const { anchors } = props;
                    const socialMediaClasses = {
                        Facebook: 'fa fa-facebook-official',
                        Twitter: 'fa fa-twitter',
                        Instagram: 'fa fa-instagram',
                        Youtube: 'fa fa-youtube-play'
                    }
                    return (
                        anchors.map((item, key) => (
                            <a key={key} href={item.url} className={item.class ? item.class.toLowerCase() : ''} target="_new">
                                <i className={socialMediaClasses[item.class] ? socialMediaClasses[item.class] : ''} />
                            </a>
                        ))
                    )
                }
                var isEvenRow = Math.abs(curTextImageRow % 2) === 1
                var imageCol = (
                    <div className={cn("col-sm-6", "col-md-4", 'image-container')}>
                        <Imgix
                            src={c.image.url}
                            style={{ width: "100%" }}
                            imgixParams={{ fm: "jpg" }}
                            htmlAttributes={{ alt: c.image.alt }}
                        />
                    </div>
                )
                var textCol = (
                    <div
                        key={c.id}
                        className={cn("col-sm-6", "col-md-8", {
                            "text-md-right": !isEvenRow,
                        })}
                    >
                        {c.title && <h3>{c.title}</h3>}
                        {c.body && (
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: supSub(c.body),
                                }}
                            />
                        )}
                        {c.extralinks && c.extralinks.length > 0 &&
                            <div className={styles.socialAnchors}>
                                <SocialAnchors anchors={c.extralinks} />
                            </div>
                        }
                    </div>
                )


                out.push(
                    <div key={c.id}>
                        <div
                            className={cn(
                                "row",
                                "d-none",
                                "d-sm-flex",
                                styles.textAndImageRow
                            )}
                        >
                            {isEvenRow ? (
                                <>
                                    <>{imageCol}</>
                                    <>{textCol}</>
                                </>
                            ) : (
                                <>
                                    <>{textCol}</>
                                    <>{imageCol}</>
                                </>
                            )}
                        </div>
                        <div
                            className={cn(
                                "xs-only",
                                "row",
                                "d-flex",
                                "d-sm-none",
                                styles.textAndImageRow
                            )}
                        >
                            <>{imageCol}</>
                            <>{textCol}</>
                        </div>
                    </div>
                )
                curTextImageRow++
                break

            case "DatoCMS_TextAndImageHeaderRecord":
                out.push(
                    <div key={c.id} className={styles.banner}>
                        <div className={styles.textBanner}>
                            <Title
                                value={c.title}
                                alignLeft
                                className={styles.title}
                            />
                            <span
                                className={styles.text}
                                dangerouslySetInnerHTML={{
                                    __html: supSub(c.body),
                                }}
                            />
                        </div>
                        <ImageBlock
                            image={c.image.url}
                            containerClass={styles.containerImageHeader}
                            imageClass={styles.image}
                        />
                    </div>
                )
                break

            case "DatoCMS_TextAndVideoHeaderRecord":
                out.push(
                    <div key={c.id} className={styles.banner}>
                        <div className={styles.textBanner}>
                            <Title value={c.title} alignLeft className={styles.title} />
                            <span className={styles.text} dangerouslySetInnerHTML={{ __html: supSub(c.body) }} />
                        </div>
                        <VideoBlock
                            youtubeVideoId={c.video.providerUid}
                            image={c.video.thumbnailUrl}
                            hideBody
                            containerClass={styles.containerImageBanner}
                            imageClass={styles.image}
                        />
                    </div>
                )
                break

            case "DatoCMS_VideoPlayerRecord":
                out.push(
                    <VideoBlock
                        key={c.video.providerUid}
                        imageClass={styles.imageVideo}
                        containerClass={styles.containerVideo}
                        hideBody
                        youtubeVideoId={c.video.providerUid}
                        image={c.video.thumbnailUrl}
                    />
                )
                break

            case "DatoCMS_HtmlRecord":
                out.push(
                    <div
                        key={c.id}
                        dangerouslySetInnerHTML={{ __html: c.html }}
                    />
                )
                break

            case "DatoCMS_ImageRecord":
                out.push(
                    <div key={c.image.id} className={styles.bannerB}>
                        <Imgix
                            src={c.image.url}
                            width="100%"
                            imgixParams={{ fit: "crop", ar: "1.6:1" }}
                            htmlAttributes={{ alt: '' }}
                        />
                    </div>
                )
                break

            case "DatoCMS_ImageGalleryRecord":
                out.push(
                    <div key={c.images[0].id}>
                        {c.images && (
                            <Gallery ctaText='View the Gallery' images={c.images} title="Blog Gallery" />
                        )}
                    </div>
                )
                break

            default:
                console.log("UNHANDLED CONTENT TYPE: " + c.__typename)
                break
        }
    }
    return out
}

export default buildContents
